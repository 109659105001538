<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
        <strong>{{ messages.tables.results }}</strong>
        <slot name="area"></slot>
        <slot name="fiscalYear"></slot>
      </span>
      <!-- {{ messages.referenceNumbers.title }} -->
      <ExportExcelButton
        :tableId="tableId"
        @export-table="exportTableToExcel($event)"
      />
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="tableData"
        :fields="fields"
        :ref="tableId"
        hover
        outlined
        border
        clickableRows
        :columnFilter="false"
        :tableFilter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import TableDataToExcel from "@/helpers/TableDataToExcel";
const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
  );

export default {
  name: "TableRefNumbers",
  components: {
    ExportExcelButton,
  },
  data() {
    return {
      activeItems: ["markets", "region"],
      lang: null,
      messages: null,
      styledItems: null,
      itemsUpperCased: null,
      user: null,
      tableId: "TableRefNumbers",
    };
  },
  computed: {
    items() {
      console.log("computedItems");
      console.log(this.tableData);
      return this.tableData;
    },
    fields() {
      return [
        {
          key: "mes",
          label: this.messages.referenceNumbers.month.toUpperCase(),
        },
        {
          key: "bonoVolumen",
          label: this.messages.products.volumeBonus.toUpperCase(),
        },
        {
          key: "accesorios",
          label: this.messages.products.accessories.toUpperCase(),
        },
        {
          key: "parque",
          label: this.messages.referenceNumbers.range.toUpperCase(),
        },
      ];
    },
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    exportTableToExcel(tableId) {
      console.log(this.$refs[tableId]._props.fields);
      console.log("-------------");
      console.log(this.$refs[tableId]._props.items);
      TableDataToExcel(
        this.$refs[tableId]._props.fields,
        this.$refs[tableId]._props.items,
        this.messages.referenceNumbers.title
      );
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
  },
};
</script>
